import { React } from '../../../common/3rd';
import { Envs } from '../../../common/common';
import './serviceOnline.scss';
import OnlineService from '../../../images/wechat/home/serviceOnline.png';
import { Utils } from '../../../common/common';

let jumpType, parent;
class ServiceOnline extends React.Component {
  constructor(props) {
    super(props);
  }
  componentWillUnmount() {}
  UNSAFE_componentWillMount() {
    jumpType = this.props.jumpType;
    parent = this.props.parent;
    // if (jumpType == 'fcm') {
    //   OnlineService =
    //     'https://app-1253531169.cos.ap-shanghai.myqcloud.com/images/fcm/addVip.png';
    // }
    // this.setState({
    //   jumpType: this.props.jumpType,
    // })
  }
  componentDidMount() {
    this.refs.serviceContainer.addEventListener(
      'touchstart',
      this.handleTouchStart,
      { passive: false }
    );
    this.refs.serviceContainer.addEventListener('touchmove', this.handleTouch, {
      passive: false,
    });
    this.refs.serviceContainer.addEventListener('touchend', this.handleTouch, {
      passive: false,
    });
    this.refs.serviceContainer.addEventListener(
      'touchcancel',
      this.handleTouchCancel,
      { passive: false }
    );
    this.refs.serviceOnlineLogo.style.left = `78vw`;
    this.refs.serviceOnlineLogo.style.top = `15vh`; //85vh
  }
  handleTouchCancel = (event) => {
    this.startPoint = null;
    if (this.isDrag) {
      this.isDrag = false;
      event.preventDefault();
    }
  };
  handleTouch = (event) => {
    if (this.isDrag) {
      if (event.type === 'touchend') {
        let rect = this.refs.serviceOnlineLogo.getBoundingClientRect();
        if (rect) {
          this.refs.serviceOnlineLogo.removeAttribute('style');
          this.refs.serviceOnlineLogo.style.left = `${rect.x}px`;
          this.refs.serviceOnlineLogo.style.top = `${rect.y}px`;
        }
        this.startPoint = null;
        this.isDrag = false;
        event.preventDefault();
        return;
      }
      let currentPoint = event.targetTouches[0];
      if (currentPoint) {
        let xOffset = currentPoint.clientX - this.startPoint.clientX;
        let yOffset = currentPoint.clientY - this.startPoint.clientY;
        this.refs.serviceOnlineLogo.style.transform = `translate(${xOffset}px, ${yOffset}px)`;
      }
      event.preventDefault();
    } else {
      if (event.type === 'touchend') {
        this.startPoint = null;
        this.isDrag = false;
        let endPoint = event.changedTouches[0];
        if (this.isServiceOnlineTouch(endPoint)) {
          this.onServiceOnlineClickLogo();
        }
      }
    }
  };
  handleTouchStart = (event) => {
    this.startPoint = event.targetTouches[0];
    if (this.isServiceOnlineTouch(this.startPoint)) {
      this.dragTimeoutID = setTimeout(() => {
        this.isDrag = this.isDetectDrag(event);
      }, 100);
      event.preventDefault();
    }
  };
  isDetectDrag = (event) => {
    return event.targetTouches.length === 1;
  };
  isServiceOnlineTouch(currentPoint) {
    let serviceOnlineRect = this.refs.serviceOnlineLogo.getBoundingClientRect();
    return (
      currentPoint &&
      serviceOnlineRect &&
      currentPoint.clientX >= serviceOnlineRect.left &&
      currentPoint.clientX <= serviceOnlineRect.right &&
      currentPoint.clientY >= serviceOnlineRect.top &&
      currentPoint.clientY <= serviceOnlineRect.bottom
    );
  }
  onServiceOnlineClickLogo = () => {
    console.log('aaaa');
    let entrance_name = '在线客服';
    if (jumpType == 'home') {
      entrance_name = '首页';
    } else if (jumpType == 'mine') {
      entrance_name = '我的';
    } else if (jumpType == 'serviceNew') {
      entrance_name = '服务';
    } else if (jumpType == 'vipNew') {
      entrance_name = '紫金荟';
    } else if (jumpType == 'serviceSearch') {
      entrance_name = '搜索';
    }
    // else if (jumpType == 'fcm') {
    //   parent.pushRedirect(Envs.PATH.WECHAT_MY_MC_INFO);
    //   return;
    // }
    Utils.setPoint(
      'click',
      'click_onlinecustomer_service',
      '【点击】在线客服',
      { entrance_name: entrance_name }
    );
    setTimeout(() => {
      let account = Envs.findAccount();
      if (!account || !account.accountId) {
        this.props.gotoSign(jumpType);
        return;
      }
      window.location.href =
        window.location.origin + '/chat/index.html' + window.location.search;
    }, 1000);
  };
  render() {
    return (
      <div className="serviceWrap" ref="serviceContainer">
        <div className="service-onlineNew" ref="serviceOnlineLogo">
          <img src={OnlineService} className="service-online-icon" />
        </div>
      </div>
    );
  }
}

export default ServiceOnline;
