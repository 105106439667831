import { React } from '../../../common/3rd';
import { Envs, Utils } from '../../../common/common';
import './renderKnowledge.scss';
let parent, page;
class RenderKnowledge extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      list: [],
    };
  }
  componentWillUnmount() {}
  UNSAFE_componentWillMount() {
    parent = this.props.parent;
    page = this.props.page;
    let url = `/rest/v1/cs/knowledge/query/byPage?page=${page}`;
    parent.doPost(url).done((data) => {
      this.setState({
        list: data.body.knowledgeBases,
      });
    });
  }
  // 去说明页
  goInstructions(code) {
    parent.pushRedirect({
      pathname: Envs.PATH.WECHAT_MY_KNOWLEDGEINSTRUCTIONS,
      code: code,
    });
  }
  componentDidMount() {}
  render() {
    return (
      <div
        className={`${
          this.state.list.length != 0
            ? 'knowledgeContainer'
            : 'knowledgeContainerNone'
        }`}
      >
        <div className="knowledgLeft">
          <div className="knowledgeTitle">
            <span>【保全攻略】</span>
          </div>
          <div className="knowledgeMain">
            {this.state.list.map((item, index) => {
              return (
                <div
                  key={item.code}
                  onClick={() => this.goInstructions(item.code)}
                >
                  <span>{`${index + 1}.${item.question}`}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div className="knowledgRight">
          <div className="knowledgeImg"></div>
        </div>
      </div>
    );
  }
}

export default RenderKnowledge;
