import { React } from '../../../common/3rd';
import { Envs, Swiper } from '../../../common/common';
import './serviceAge.scss';
import { Popup, Space, Button } from 'antd-mobile';
import check1 from '../../../images/mobile/checkbox_1.png';
import check2 from '../../../images/mobile/checkbox_2.png';
let comeIn, letterBook, letterName;
class serviceAgeNew extends React.Component {
  constructor(context) {
    super();
    this.state = {
      visible: false,
    };
  }
  componentWillUnmount() {}
  componentDidMount() {}
  UNSAFE_componentWillMount() {
    comeIn = this.props.comeIn;
    letterName = this.props.letterName;
  }
  render() {
    if (
      comeIn == 'DB' ||
      comeIn == 'BM' ||
      comeIn == 'WP' ||
      comeIn == 'GM' ||
      comeIn == 'SA' ||
      comeIn == 'LN' ||
      comeIn == 'PT' ||
      comeIn == 'RE' ||
      comeIn == 'IO' ||
      comeIn == 'WT' ||
      comeIn == 'OP' ||
      comeIn == 'BS' ||
      comeIn == 'CT' ||
      comeIn == 'HI' ||
      comeIn == 'LG'
    ) {
      letterBook =
        '本人同意采用电子签名的方式签署保全申请材料，此电子签名可同时用于《个人保险合同保全业务申请书》、《个人保险合同保单借款申请书》、《保险合同遗失声明》、《个人情况告知书》、《保险合同效力确认书》等文件的签署，';
    } else if (comeIn == 'NS') {
      letterBook =
        '本人同意采用电子签名的方式签署保全申请材料，此电子签名可同时用于《个人保险合同保全业务申请书》、《个人保险合同保单借款申请书》、《保险合同遗失声明》、《个人情况告知书》、《保险合同效力确认书》、《产品说明书》、《客户身份识别登记表》等文件的签署，';
    } else if (
      comeIn == 'BB' ||
      comeIn == 'PC' ||
      comeIn == 'AE' ||
      comeIn == 'OD' ||
      comeIn == 'RF'
    ) {
      letterBook =
        '本人同意采用电子签名的方式签署保全申请材料，此电子签名可同时用于《个人保险合同保全业务申请书》、《个人保险合同保单借款申请书》、《保险合同遗失声明》、《个人情况告知书》、《健康告知书》、《客户身份识别登记表》、《产品说明书》、《保险合同效力确认书》等文件的签署，';
    } else if (comeIn == 'AG' || comeIn == 'AM' || comeIn == 'BC') {
      letterBook =
        '本人同意采用电子签名的方式签署保全申请材料，此电子签名可同时用于《个人保险合同保全业务申请书》、《客户信息登记表》、《个人税收居民身份声明文件》、《保险合同作废声明》等文件的签署，';
    } else if (comeIn == 'claims') {
      //理赔
      letterBook =
        '本人同意此电子签名可同时用于《个人保险合同理赔申请书》、《个人税收居民身份声明文件》等文件的签署，';
    } else if (comeIn == 'visit') {
      //回访
      letterBook =
        '本人同意采用电子签名的方式签署北京人寿新契约回访问卷，此电子签名仅用于北京人寿新契约回访问卷的签署，';
    } else if (comeIn == 'receipt') {
      //回执
      letterBook =
        '本人同意采用电子签名的方式回销回执，此电子签名可同时用于《电子回执》文件的签署，';
    } else if (comeIn == 'receipt') {
      //回执
      letterBook = `本人同意采用电子签名的方式回销本函件${letterName}，`;
    }
    return (
      <div className="signExplain" onClick={(e) => this.onCheckNew(e.target)}>
        <img src={check1} className="icon-sign" data-val="N" alt="" />
        {letterBook}
        同意由北京数字认证股份有限公司为电子签名提供认证服务，自愿遵守其
        <div className="ageColor" onClick={(e) => this.onServiceAge()}>
          电子认证服务协议
        </div>
        ，并保证提交的认证申请信息真实、准确、完整，愿意承担由于资料虚假失实而导致的一切后果。
        <Popup
          visible={this.state.visible}
          showCloseButton
          onMaskClick={() => this.setVisible(false)}
          onClose={() => this.setVisible(false)}
          bodyStyle={{
            borderTopLeftRadius: '16px',
            borderTopRightRadius: '16px',
            height: '50vh',
          }}
          bodyClassName="serviceAge"
        >
          <div className="serviceIn">
            <div className="note-title note-center">
              北京数字认证股份有限公司电子认证服务协议
            </div>
            <div>
              <div>
                数字证书是电子认证服务机构签发的包含数字证书使用者身份信息和公开密钥的电子文件。
              </div>
              <div>
                北京数字认证股份有限公司（以下简称“数字认证公司”），是工业和信息化部批准设立的电子认证服务机构和国家密码管理局批准设立的电子政务电子认证服务机构，遵照《中华人民共和国电子签名法》为用户提供数字证书相关的电子认证服务。
              </div>
              <div>
                本协议中的用户指数字证书持有人以及申请使用数字证书的实体。
              </div>
              <div className="note-title">
                为明确各方权利和义务，数字认证公司制定《北京数字认证股份有限公司电子认证服务协议》（以下简称“本协议”），一旦申请使用数字认证公司电子认证服务，即表示用户同意接受并愿意遵守本协议的所有条款。
              </div>
            </div>
            <div className="note-title">第一条用户的权利和义务</div>
            <div>
              1.用户有权要求数字认证公司按照本协议和《北京数字认证股份有限公司电子认证业务规则》(以下简称“CPS”)之规定提供电子认证服务。
            </div>
            <div>
              2.
              <span className="note-blod">
                用户申请数字证书，应依法提供真实、完整和准确的信息及证明材料，
              </span>
              并在发生变更之日起两个自然日内通知数字认证公司或其授权的注册机构,
              如因故意或过失未提供真实、完整和准确的信息，或提供其非法获取的相关信息及资料、或发生变更后未在上述时限内通知数字认证公司或其授权的注册机构，导致签发证书错误，造成相关各方损失的，由用户承担相关责任。
            </div>
            <div>
              3.用户对数字证书的使用必须符合中国相关法律法规、本协议和CPS，并对使用数字证书的行为负责。
            </div>
            <div>
              4.在证书到期或被吊销时，用户应立即停止使用所有与证书中公钥相对应的私钥。
            </div>
            <div className="note-blod">
              5.数字证书一律不得转让、转借或转用。因转让、转借或转用而产生的相关后果应当由用户自行承担。
            </div>
            <div className="note-title">第二条数字认证公司的权利和义务</div>
            <div>
              1.数字认证公司或其授权的注册机构有权不予受理用户未明确说明证书用途或无正当证
              书用途的申请，有权拒绝未通过最终审核的申请。
            </div>
            <div>
              2.数字认证公司或其授权的注册机构根据数字认证公司安全操作流程要求以及CPS进行
              签发和管理数字证书，不对用户、依赖方或其他任何第三方因使用或依赖该证书而造成的任
              何损失承担责任。
            </div>
            <div>
              3.数字认证公司严格按照CPS存储并采取有效措施保护用户提交的信息、资料。
            </div>
            <div>
              4.数字认证公司承诺，在现有的技术条件下，由数字认证公司签发的数字证书能够有效
              防止被伪造、篡改。如经确认确属数字认证公司责任，数字认证公司承担赔偿责任，且以
              CPS之规定为赔偿责任上限。
            </div>
            <div className="note-title">第三条申请</div>
            <div>
              1.用户为申请办理数字证书、使用电子认证服务等目的，需向数字认证公司提交相应用
              户信息和证明材料。
            </div>
            <div>
              2.数字认证公司或其授权的注册机构作为证书业务受理单位和服务支持单位，负责用户
              的信息录入、身份审核和证书制作工作，应完全遵守数字认证公司安全操作流程进行用户身
              份审核和证书制作。
            </div>
            <div>
              3.用户在获得数字证书时应及时验证此证书所匹配的信息，如无异议则视为接受证书。
            </div>
            <div className="note-title">第四条使用</div>
            <div>
              1.数字认证公司签发的数字证书的密钥用法在证书的扩展项中进行了限制，用户使用数
              字证书的行为应符合该限制。
            </div>
            <div className="note-blod">
              2.云端协同证书用户同意通过验证身份凭证（包括但不限于短信验证码、口令、人脸识
              别）的方式授权数字认证公司使用其所有的数字证书，以用户的名义采用电子签名的方式签
              署电子文件。用户已充分知悉该授权的意义以及由此产生的法律效力，自愿作出授权。
            </div>
            <div className="note-blod">
              3.用户应当妥善保管数字认证公司签发的数字证书、私钥、保护密码及云端协同证书身
              份凭证的安全，不得泄露或交付他人。用户知悉或应当知悉证书私钥、保护密码及云端协同
              证书身份凭证已经或可能泄露、损毁、丢失时，应当及时采取有效措施防止数字证书被不当
              使用，如未终止使用证书也未通知数字认证公司或其授权的注册机构的，由此产生的相关责
              任数字认证公司概不承担。
            </div>
            <div className="note-title">第五条更新</div>
            <div>
              1.数字证书有效期到期后，用户若仍需继续使用数字证书，必须在到期前三十个自然日
              内向数字认证公司或其授权的注册机构提出更新请求。否则，证书到期将自动失效；用户使
              用事件型数字证书或云端协同证书的除外。
            </div>
            <div>
              2.因技术需要，数字认证公司按规定要求用户及时更新数字证书。用户在收到更新通知
              后，应在规定的期限内到数字认证公司或其授权的注册机构更新证书。
            </div>
            <div className="note-title">第六条吊销</div>
            <div>
              1.如遇数字证书私钥泄露、损毁、丢失、证书中的信息发生重大变更、或用户不希望继
              续使用数字证书、发现数字证书被不当使用的情况，用户应当立即到数字认证公司或其授权
              的注册机构申请吊销证书。吊销手续遵循各注册机构的规定。数字认证公司或其授权的注册
              机构在接到吊销申请后并对申请资料审核无误后，在24小时内吊销用户的数字证书。用户使
              用事件型数字证书的除外。
            </div>
            <div>
              2.如数字认证公司发现用户存在提供信息不真实、证书被滥用、证书的安全性不能得到
              保障、用户未履行本协议、CPS中规定其他吊销情形时，数字认证公司有权不经事先通知用
              户，直接吊销证书。
            </div>
            <div className="note-title">第七条个人信息保护</div>
            <div>
              <span className="note-blod">
                1.用户同意向数字认证公司提交办理数字证书所必要的身份信息
              </span>
              息（个人用户需提交姓
              名、身份证号、实名手机号、电子邮箱、联系地址、所在单位信息；企业用户需提交企业名
              称、组织机构代码/统一社会信用代码、通信地址、法定代表人及经办人姓名、身份证号、
              实名手机号），
              <span className="note-blod">
                且用户授权数字认证公司向其第三方合作机构传递前述信息用于核实用户身
                份。
              </span>
            </div>
            <div>
              2.根据监管的要求，数字认证公司需妥善保存与认证相关的用户身份信息。
            </div>
            <div>
              3.根据法律法规、强制性的行政执法或司法要求必须提供用户身份信息的情况下，数字
              认证公司将依据要求对外共享、转让、公开披露相关信息。
            </div>
            <div className="note-blod">
              4.本协议有关个人信息保护条款的完整内容见数字认证公司网站上公布的《个人信息保
              护政策》。
            </div>
            <div className="note-title">第八条责任限制与免除</div>
            <div>
              1.用户故意或无意地提供了不完整、不可靠或已过期的信息，又根据正常的流程提供了
              必须的审核文件，得到了数字认证公司签发的数字证书，由此引起的一切后果应由用户全部
              承担，数字认证公司不承担与证书内容相关的责任，但可以根据请求提供协查帮助。
            </div>
            <div>
              2.用户知悉数字证书制作数据已经失密或者可能已经失密未及时告知有关各方、并终止
              使用该制作数据，未向数字认证公司提供真实、完整和准确的信息，或者有其他过错，给数
              字证书依赖方、数字认证公司造成损失的，承担赔偿责任。
            </div>
            <div>
              3.数字认证公司与注册机构合作，约定由注册机构承担身份验证义务的，对于由注册机
              构过错导致用户受有损失的，由注册机构承担全部赔偿责任，与数字认证公司无关。用户应
              当直接向注册机构主张赔偿责任。
            </div>
            <div>
              4.因用户的网络、主机、操作系统或其他软硬件环境等存在安全漏洞，由此导致的安全
              事故及相关后果，用户自行承担责任。
            </div>
            <div>
              5.如数字认证公司已谨慎地遵循且履行了国家法律、法规及CPS之规定，视为数字认证
              公司不存在过错，无须对因此产生的损失承担赔偿责任。
            </div>
            <div className="note-title">第九条其他</div>
            <div className="note-title">
              本协议条款可由数字认证公司随时更新，数字认证公司会通过网站
              https://www.bjca.cn进行公布，更新后的协议一旦公布即替代原来的协议条款。用户如果
              不接受修改后的协议，可于发布之日起十五日内，向数字认证公司授权的注册机构提出吊销
              证书的申请。如果逾期没有提出异议，则视为同意接受修订后的协议。
            </div>
            <div className="note-wrap">
              1.本协议与数字认证公司网站上公布的《北京数字认证股份有限公司电子认证业务规
              则》
              (https://www.bjca.cn/u4d/%E7%94%B5%E5%AD%90%E8%AE%A4%E8%AF%81%E4%B
              8%9A%E5%8A%A1%E8%A7%84%E5%88%99%EF%BC%88CPS%EF%BC%89/files/BJ
              CA-CPS.pdf)、《个人信息保护政策》
              (https://service.isignet.cn/private/agreement.html)共
              同构成关于数字证书的完整协议。
            </div>
            <div>
              2.本协议的有效期限为证书的有效期限。证书有效期限届满，用户更新证书的，本协议
              有效期限顺延至证书更新期限届满日。
            </div>
            <div>
              3.本协议之效力、解释、变更、执行与争议解决均适用中华人民共和国法律。因本协议
              产生之争议，首先应经友好协商解决，协商不成的，双方同意将争议提交北京仲裁委员会并
              根据其现行有效的仲裁规则在北京申请仲裁，所作裁决是终局的，对双方有约束力。
            </div>
            <div>
              4.如本协议中的任何条款无论因何种原因完全或部分无效，本协议的其余条款仍应有
              效。
            </div>
            <div>5.数字认证公司对本协议享有最终解释权。</div>
            <div>
              6.用户确认已经认真阅读并完全理解本协议中的各项条款，用户在申请表上签名、盖
              章，或初次使用数字证书的行为，均表明接受本协议的约束，本协议即时生效。
            </div>
          </div>
        </Popup>
      </div>
    );
  }
  onServiceAge = () => {
    console.log($('.icon-sign').attr('data-val'), 1111);
    if ($('.icon-sign').attr('data-val') == 'N') {
      $('.icon-sign').attr('src', check2);
      $('.icon-sign').attr('data-val', 'Y');
    } else if ($('.icon-sign').attr('data-val') == 'Y') {
      $('.icon-sign').attr('src', check1);
      $('.icon-sign').attr('data-val', 'N');
    }
    this.setState({
      visible: true,
    });
  };
  setVisible = (type) => {
    this.setState({
      visible: false,
    });
  };
  /*单子签名阅读*/
  onCheckNew = (e) => {
    if ($('.icon-sign').attr('data-val') == 'N') {
      $('.icon-sign').attr('src', check2);
      $('.icon-sign').attr('data-val', 'Y');
    } else if ($('.icon-sign').attr('data-val') == 'Y') {
      $('.icon-sign').attr('src', check1);
      $('.icon-sign').attr('data-val', 'N');
    }
  };
}

export default serviceAgeNew;
