import Utils from './utils';
import Envs from './envs';
import PageLoading from '@/component/both/loading/page-loading';
import Alert from '@/component/both/alert/alert';
import axios from 'axios';
import Dictionary from './dictionary';
//spk 阿里与微保 code 集合  阿里=>微保
let bankCodeList = Dictionary.bankCodeList;
class OcrTools {
  ocrUrl = '/rest/v1/document/ocr/recognition';
  uploadUrl = '/rest/v1/document/file/uploadFile';
  uploadUrlNew = '/rest/v1/policy/uploadImage';
  getToken(parent) {
    Utils.safeTokenNew(parent);
    let timestamp = Date.parse(new Date());
    let json = { timestamp: timestamp };
    let tokenBase64 = Utils.makeSafeTokenUrl(json);
    return tokenBase64;
  }
  getOptions() {
    let uuid = Utils.getQueryStringObject().uuid;
    return uuid ? { ignoreAuth: true } : {};
  }
  /**
   * obj.ContNo 保单号
   * obj.imgtype 图像格式
   * obj.document base64
   * obj.checkUser 检验身份证
   * 需要绑定this
   * repeat =1 当token失效时 回调本身
   */
  //正面 识别成功后 校验证件号准确性
  ocrFront(policyNo, identityIdNO, data, callBack, parent, repeat = 1) {
    let imgtype = data.split(',')[0].split('/')[1].split(';')[0];
    let document = data.split(',')[1];
    let requestData = {
      vendorCode: 'BJLIFE',
      documentType: 0,
      isFront: 'Y',
      customerType: '1',
      businessNo: policyNo,
      username: 'test',
      signdata: 'NULL',
      imgtype: imgtype,
      document: document,
    };
    PageLoading.start();
    let tokenBase64 = ocrTools.getToken(parent);
    let resName = ''; //姓名
    let residNo = ''; //证件号
    let url = ''; //识别影像地址
    let error = null;
    let options = ocrTools.getOptions();
    parent
      .doPost(
        `${ocrTools.ocrUrl}?bjlifeToken=${tokenBase64}`,
        requestData,
        options
      )
      .done((res) => {
        PageLoading.end();
        if (res.returnCode == 'OCR_SUC') {
          let cardInfoObj = JSON.parse(res.body.extensionData).cardsinfo.card
            .item;
          let resNameArr = cardInfoObj.filter((i) => i.desc == '姓名');
          resName = resNameArr[0].content;
          let residNoArr = cardInfoObj.filter((i) => i.desc == '公民身份号码');
          residNo = residNoArr[0].content;
          url = {
            docPath: res.body.docPath,
            docName: res.body.docName,
          };
          //校验证件格式
          if (Utils.validateIDCard(residNo, '0')) {
            error = Utils.validateIDCard(residNo, '0');
            return;
          }
          if (
            identityIdNO &&
            residNo.toLowerCase() !== identityIdNO.toLowerCase()
          ) {
            error = '身份证照片与投保人信息不符';
          }
        } else {
          error = '身份证照片识别有误,请您重新上传。';
        }
        if (!callBack || typeof callBack !== 'function') return;
        let callBack_data = {
          resName,
          residNo,
          url,
        };
        if (error) {
          Alert.message(error);
          callBack_data = null;
        }
        callBack(callBack_data);
      })
      .fail((fail) => {
        PageLoading.end();
        //token失效删除token 并重新回调1次
        if (fail.responseText) {
          let eRes = JSON.parse(fail.responseText).errors[0].code;
          if (
            (eRes == 'BJLIFE_TOKEN_IS_NOT_VALID' ||
              eRes == 'BJLIFE_TOKEN_IS_NULL') &&
            repeat == 1
          ) {
            localStorage.removeItem('safeToken');
            localStorage.removeItem('tokenLife');
            ocrTools.ocrFront(policyNo, identityIdNO, data, callBack, parent);
          }
          return;
        }
        if (callBack && typeof callBack == 'function') {
          callBack('');
        }
      });
  }
  //反面 识别成功后 校验日期格式 , 是否过期
  ocrBack(policyNo, data, callBack, parent, repeat = 1) {
    let imgtype = data.split(',')[0].split('/')[1].split(';')[0];
    let document = data.split(',')[1];
    let requestData = {
      vendorCode: 'BJLIFE',
      documentType: 0,
      isFront: 'N',
      customerType: '1',
      businessNo: policyNo,
      username: 'test',
      signdata: 'NULL',
      imgtype: imgtype,
      document: document,
    };
    PageLoading.start();
    let tokenBase64 = ocrTools.getToken(parent);
    let timeStart = ''; //证件有效期起期
    let time = ''; //证件有效期止期
    let url = ''; //识别影像地址
    let error = null;
    let options = ocrTools.getOptions();
    parent
      .doPost(
        `${ocrTools.ocrUrl}?bjlifeToken=${tokenBase64}`,
        requestData,
        options
      )
      .done((res) => {
        PageLoading.end();
        if (res.returnCode == 'OCR_SUC') {
          let cardInfoObj = JSON.parse(res.body.extensionData).cardsinfo.card
            .item;
          let dateStart = cardInfoObj.filter((i) => i.desc == '签发日期');
          timeStart = dateStart[0].content;
          let dateEnd = cardInfoObj.filter((i) => i.desc == '有效期至');
          time = dateEnd[0].content;
          url = {
            docPath: res.body.docPath,
            docName: res.body.docName,
          };
          //日期格式
          let reg = /^(\d{4})-(0\d{1}|1[0-2])-(0\d{1}|[12]\d{1}|3[01])$/;
          //是否长期
          if (
            time &&
            (time == '长期' || time.includes('长') || time.includes('期'))
          ) {
            time = '2200-12-31';
          } else if (time && time.length == 10 && reg.test(time)) {
            //是否符合日期格式
            var timeCard = Date.parse(time.replace(/\-/g, '/') + ' 00:00:00');
            var timeNow = Date.parse(res.time);
            //有效期过期
            if (timeCard <= timeNow) {
              error = `识别失败，请确认身份证尚在有效期内并重新上传身份证影像。`;
            }
          } else {
            //识别错误
            error = `识别失败，请确认身份证尚在有效期内并重新上传身份证影像。`;
          }
        } else {
          error = '身份证照片识别有误,请您重新上传。';
        }

        if (!callBack || typeof callBack !== 'function') return;
        let callBack_data = {
          timeStart,
          time,
          url,
        };
        if (error) {
          Alert.message(error);
          callBack_data = null;
        }
        callBack(callBack_data);
      })
      .fail((fail) => {
        PageLoading.end();
        //token失效删除token 并重新回调1次
        if (fail.responseText) {
          let eRes = JSON.parse(fail.responseText).errors[0].code;
          if (
            (eRes == 'BJLIFE_TOKEN_IS_NOT_VALID' ||
              eRes == 'BJLIFE_TOKEN_IS_NULL') &&
            repeat == 1
          ) {
            localStorage.removeItem('safeToken');
            localStorage.removeItem('tokenLife');
            ocrTools.ocrBack(policyNo, data, callBack, parent);
          }
          return;
        }

        if (callBack && typeof callBack == 'function') {
          callBack(null);
        }
      });
  }
  //上传图片/户口本/出生证 repeat 默认值为1，
  uploadFile(policyNo, data, callBack, parent, repeat = 1) {
    let document = '';
    if (data.indexOf('image/jpeg') > 0) {
      document = data.substring(23, data.length);
    } else {
      document = data.substring(22, data.length);
    }
    let requestData = {
      vendorCode: 'BJLIFE',
      documentType: '99',
      isFront: 'Y',
      document: document,
    };
    //获取token
    let tokenBase64 = ocrTools.getToken(parent);
    let url = '';
    let error = null;
    let options = ocrTools.getOptions();
    PageLoading.start();
    parent
      .doPost(
        `${ocrTools.uploadUrl}?bjlifeToken=${tokenBase64}`,
        requestData,
        options
      )
      .done((res) => {
        PageLoading.end();
        if (res.returnCode == 'RC-00001') {
          let { docPath, docName } = res.body;
          url = {
            docPath: docPath,
            docName: docName,
          };
        } else {
          error = '上传失败，请确认上传正确影像。';
        }
        if (!callBack || typeof callBack !== 'function') return;
        let callBack_data = {
          url,
          time: '2200-12-31',
        };
        if (error) {
          Alert.message(error);
          callBack_data = null;
        }
        callBack(callBack_data);
      })
      .fail((fail) => {
        PageLoading.end();
        //token失效删除token 并重新回调1次
        if (fail.responseText) {
          let eRes = JSON.parse(fail.responseText).errors[0].code;
          if (
            (eRes == 'BJLIFE_TOKEN_IS_NOT_VALID' ||
              eRes == 'BJLIFE_TOKEN_IS_NULL') &&
            repeat == 1
          ) {
            localStorage.removeItem('safeToken');
            localStorage.removeItem('tokenLife');
            ocrTools.uploadFile(policyNo, data, callBack, parent);
          }
          return;
        }
        if (callBack && typeof callBack == 'function') {
          callBack(null);
        }
      });
  }
  //上传个险照片
  uploadFileNew(claimNo, data, callBack, parent, repeat = 1) {
    let document = '';
    if (data.indexOf('image/jpeg') > 0) {
      document = data.substring(23, data.length);
    } else {
      document = data.substring(22, data.length);
    }
    let requestData = {
      businessNo: claimNo,
      customerType: '0',
      document: document,
      documentType: '',
      isFront: 'Y',
      productId: '',
      uplaodType: '3',
      vendorCode: 'BJLIFE',
    };
    //获取token
    let tokenBase64 = ocrTools.getToken(parent);
    let url = '';
    let error = null;
    let options = ocrTools.getOptions();
    PageLoading.start('上传资料中，请稍后...');
    parent
      .doPost(`${ocrTools.uploadUrlNew}`, requestData, options)
      .done((res) => {
        PageLoading.end();
        if (res.returnCode == 'RC-00001') {
          let { fileLocation, uploadTime } = res.body;
          url = {
            fileLocation: fileLocation,
            uploadTime: uploadTime,
          };
        } else {
          error = '上传失败，请确认上传正确影像。';
        }
        if (!callBack || typeof callBack !== 'function') return;
        let callBack_data = {
          url,
        };
        if (error) {
          Alert.message(error);
          callBack_data = null;
        }
        callBack(callBack_data);
      })
      .fail((fail) => {
        PageLoading.end();
        //token失效删除token 并重新回调1次
        if (fail.responseText) {
          let eRes = JSON.parse(fail.responseText).errors[0].code;
          if (
            (eRes == 'BJLIFE_TOKEN_IS_NOT_VALID' ||
              eRes == 'BJLIFE_TOKEN_IS_NULL') &&
            repeat == 1
          ) {
            localStorage.removeItem('safeToken');
            localStorage.removeItem('tokenLife');
            ocrTools.uploadFile(claimNo, data, callBack, parent);
          }
          return;
        }
        if (callBack && typeof callBack == 'function') {
          callBack(null);
        }
      });
  }
  //银行卡
  uploadBank(policyNo, data, callBack, parent, repeat = 1) {
    let imgtype = data.split(',')[0].split('/')[1].split(';')[0];
    let document = data.split(',')[1];
    let requestData = {
      vendorCode: 'BJLIFE',
      documentType: -1,
      isFront: 'Y',
      customerType: '1',
      businessNo: policyNo, //'EBJE0600190212000',
      username: 'test',
      signdata: 'NULL',
      imgtype: imgtype,
      document: document,
    };
    PageLoading.start();
    let tokenBase64 = ocrTools.getToken(parent);
    let url = ''; //识别影像地址
    let options = ocrTools.getOptions();
    let error = null;
    parent
      .doPost(
        `${ocrTools.ocrUrl}?bjlifeToken=${tokenBase64}`,
        requestData,
        options
      )
      .done((res) => {
        PageLoading.end();
        if (res.returnCode == 'OCR_SUC') {
          let cardInfoObj = JSON.parse(res.body.extensionData).cardsinfo.card
            .item;
          let bankNo = cardInfoObj.filter((i) => i.desc == '卡号');
          bankNo = bankNo[0].content;
          let idImgInfo = {
            url: res.body.docPath,
            fileName: res.body.docName,
            sequenceNo: 1,
          };
          //调取阿里接口
          let aLiUrl = `https://ccdcapi.alipay.com/validateAndCacheCardInfo.json?_input_charset=utf-8&cardNo=${bankNo}&cardBinCheck=true`;
          axios
            .get(aLiUrl)
            .then((response) => {
              const res = response.data;
              if (res.stat == 'ok') {
                if (res.cardType == 'CC') {
                  error = '不支持信用卡，请使用借记卡';
                } else {
                  let bankCode = bankCodeList[res.bank];
                  let callBack_data = {
                    bankNo,
                    bankCode,
                    idImgInfo,
                  };
                  callBack(callBack_data);
                }
              }
            })
            .catch((error) => {
              error = '银行卡照片识别有误,请您重新上传。';
            });
        } else {
          error = '银行卡照片识别有误,请您重新上传。';
        }
        if (error) {
          Alert.message(error);
          callBack(null);
          return;
        }
      })
      .fail((fail) => {
        PageLoading.end();
        //token失效删除token 并重新回调1次
        if (fail.responseText) {
          let eRes = JSON.parse(fail.responseText).errors[0].code;
          if (
            (eRes == 'BJLIFE_TOKEN_IS_NOT_VALID' ||
              eRes == 'BJLIFE_TOKEN_IS_NULL') &&
            repeat == 1
          ) {
            localStorage.removeItem('safeToken');
            ocrTools.uploadBank(policyNo, data, callBack, parent);
          }
          return;
        }
        callBack(null);
      });
  }
}
let ocrTools = new OcrTools();
export default ocrTools;
